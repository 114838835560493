import { FunctionComponent, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import { useOrganisationRelatedStoriesQuery } from '../../../../services';
import { Button, ButtonType, GalleryItem, Select, Switch } from '../../../../shared';

import classNames from 'classnames';
import 'react-loading-skeleton/dist/skeleton.css';
import classes from './StorySync.module.scss';

export interface StorySyncSource {
  enabled: boolean;
  channelsId: number[];
  storiesId: number[];
  fetchLastCards: number;
}

interface StorySyncProps {
  source: StorySyncSource;
  setSource: (source: StorySyncSource) => void;
  setTitle: (title: string) => void;
  setSubtitle: (subTitle: string) => void;
  setGalleryItems: (gallery: GalleryItem[]) => void;
  storyId?: number;
  withToggle?: boolean;
}

export const StorySync: FunctionComponent<StorySyncProps> = memo(
  ({ source, setSource, setTitle, setSubtitle, setGalleryItems, storyId, withToggle }) => {
    const { t } = useTranslation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { data: channels, isFetching } = useOrganisationRelatedStoriesQuery({ channelId });

    const { enabled, channelsId, storiesId, fetchLastCards } = source;

    const selectedChannelId = useMemo(() => channelsId[0], [channelsId]);

    const selectedStorylId = useMemo(() => storiesId[0], [storiesId]);

    const editMode = useMemo(() => Boolean(storyId), [storyId]);

    const channelItems = useMemo(
      () =>
        channels?.map(({ id, name }) => {
          return { id, title: name };
        }) ?? [],
      [channels]
    );

    const storyItems = useMemo(
      () => channels?.find(({ id }) => id === selectedChannelId)?.stories ?? [],
      [channels, selectedChannelId]
    );

    const channelSelectDisabled = useMemo(
      () => !Boolean(channelItems.length),
      [channelItems.length]
    );

    const storySelectDisabled = useMemo(() => !Boolean(storyItems.length), [storyItems.length]);

    const fetchLastItemsOptions = useMemo(() => {
      return [0, 2, 5, 10, 15, 20, 50, 75, 100].map((item) => {
        return { id: item, title: item.toString() };
      });
    }, []);

    const toggleEnabled = useCallback(
      (enabled: boolean) => setSource({ ...source, enabled }),
      [setSource, source]
    );

    const onChannelChange = useCallback(
      (channelId: number) => {
        const storyItems = channels?.find(({ id }) => id === channelId)?.stories ?? [];
        setSource({ ...source, channelsId: [channelId], storiesId: [storyItems[0]?.id] });
      },
      [channels, setSource, source]
    );

    const onStoryChange = useCallback(
      (storyId: number) => setSource({ ...source, storiesId: [storyId] }),
      [setSource, source]
    );

    const onFetchLastItemsChange = useCallback(
      (fetchLastCards: number) => setSource({ ...source, fetchLastCards }),
      [setSource, source]
    );

    const copyFromSource = useCallback(() => {
      const story = storyItems.find(({ id }) => id === selectedStorylId);

      if (!story) {
        return;
      }

      const { title, subtitle, image } = story;

      setTitle(title);
      setSubtitle(subtitle);
      setGalleryItems(image ? [{ image }] : []);
    }, [selectedStorylId, setGalleryItems, setSubtitle, setTitle, storyItems]);

    useEffect(() => {
      if (!selectedChannelId && channelItems.length) {
        onChannelChange(channelItems[0].id);
      }
    }, [channelItems, onChannelChange, selectedChannelId]);

    const content = useMemo(() => {
      if (isFetching) {
        return <Skeleton height={'5rem'} />;
      }

      return (
        <>
          {withToggle && (
            <Switch
              inputId={'story-sync'}
              label={t('createStorySettings.story-sync-switch-label')}
              checked={enabled}
              onChange={toggleEnabled}
            />
          )}
          <Select
            label={t(`common.${channelSelectDisabled ? 'no-channel-available' : 'channel'}`)}
            disabled={!enabled || channelSelectDisabled}
            items={channelItems}
            selectedItemId={selectedChannelId}
            listMaxHeightRem={19.5}
            onChange={onChannelChange}
          />
          <div className={classes['story-sync__story-wrapper']}>
            <Select
              key={selectedStorylId}
              label={t(`common.${storySelectDisabled ? 'no-story-available' : 'story'}`)}
              disabled={!enabled || storySelectDisabled}
              items={storyItems}
              selectedItemId={selectedStorylId}
              className={classNames(classes['story-sync__story'], {
                [classes['story-sync__story--full-width']]: editMode,
              })}
              listMaxHeightRem={13.5}
              onChange={onStoryChange}
            />
            {!editMode && (
              <Select
                label={t('common.fetch-last-items')}
                items={fetchLastItemsOptions}
                listMaxHeightRem={13.5}
                selectedItemId={fetchLastCards}
                className={classes['story-sync__story-last-items']}
                onChange={onFetchLastItemsChange}
                disabled={!enabled}
              />
            )}
          </div>
          <Button
            type={ButtonType.secondary}
            label={t('createStory.copy-from-source-story')}
            className={classes['story-sync__story-copy-from-source']}
            onClick={copyFromSource}
            disabled={!enabled}
          />
        </>
      );
    }, [
      channelItems,
      channelSelectDisabled,
      copyFromSource,
      editMode,
      enabled,
      fetchLastCards,
      fetchLastItemsOptions,
      isFetching,
      onChannelChange,
      onFetchLastItemsChange,
      onStoryChange,
      selectedChannelId,
      selectedStorylId,
      storyItems,
      storySelectDisabled,
      t,
      toggleEnabled,
      withToggle,
    ]);

    return <div className={classes['story-sync']}>{content}</div>;
  }
);
