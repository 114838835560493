interface StoryQueryArgs {
  isAdminLayout: boolean;
}

export const storyQuery = ({ isAdminLayout }: StoryQueryArgs) => `
    author {
        id
        avatar {
            averageColor
            externalId
            externalType
            id
            originalFilename
            rightholder
            status
            statusCopyrightId
            thumb
            url
        }
        screenName
    }
    authorId
    cardsCount
    channelId
    id
    image {
        averageColor
        id
        originalFilename
        rightholder
        status
        statusCopyrightId
        thumb
        url
    }
    imageId
    isReadOnly
    localeId
    pinned
    position
    settings {
        access
        allowAccessToEditorLimited
        allowAccessToReader
        allowCardReactions
        allowComments
        allowSharing
        autoNotification {
            enabled
            titleDefault
            titlePrefix
            messageDefault
        }
        cardStyles {
            article {
              teaserStyle
            }
        }
        displayItemUpdatedTime
        highlightingComments
        horizontalDisplayView {
            position
        }
        includeInMixTab
        includeInMixTab2
        includeInNewsFeed
        includeInWebappNewsFeed
        section
        shouldRestrictDuplicateUrlOfCardsInTimeFrame
        shouldSyncItemPositionsOnConnectedStories
        shouldUpdatePostedTimeByComments
        showAllAuthors
        cardLimit
    }
    externalTags: organisationTags(filters: {types: [EXTERNAL]}) { id name type }
    ${
      isAdminLayout
        ? `internalTags: organisationTags(filters: {types: [INTERNAL]}) { id name type }`
        : ''
    }
    ${isAdminLayout ? `sources { channel { id subdomain } story { id } }` : ''}
    status
    subtitle
    title
    type
`;
