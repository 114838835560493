import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelAppTabAction, Story, StorySection, StoryType } from '../../../services';
import { useAppTabsLabel } from '../../hooks';

import classes from './StoryTags.module.scss';

interface StoryTagsProps {
  story: Story;
}

export const StoryTags: FunctionComponent<StoryTagsProps> = memo(({ story }) => {
  const { t } = useTranslation();

  const { getAppTabsLabel } = useAppTabsLabel();

  const { type, settings, pinned, sources = [] } = { ...story };

  const { section, horizontalDisplayView, allowAccessToEditorLimited, allowAccessToReader } =
    settings;

  const isReadOnly = useMemo(() => type === StoryType.READONLY, [type]);

  const isSync = useMemo(
    () => Boolean(!isReadOnly && sources.length),
    [isReadOnly, sources.length]
  );

  return (
    <div className={classes['story-tags']}>
      {section.map((key) => {
        switch (key) {
          case StorySection.WEB_FEED:
            return (
              <span key={key}>
                #{`${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.web')}`}
              </span>
            );
          case StorySection.MOBILE_FEED:
            return (
              <span key={key}>
                #{`${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.mobile')}`}
              </span>
            );
          case StorySection.MIX_LIST:
            return <span key={key}>#{getAppTabsLabel(ChannelAppTabAction.STORIES)}</span>;
          case StorySection.MIX_LIST_2:
            return <span key={key}>#{getAppTabsLabel(ChannelAppTabAction.STORIES2)}</span>;
          case StorySection.PINNED:
            return (
              <span key={key}>
                #
                {`${getAppTabsLabel(ChannelAppTabAction.PINNED_STORY)} (${t(
                  `common.at-${pinned.toLowerCase()}`
                )})`}
              </span>
            );
          case StorySection.HORIZONTAL:
            return (
              <span key={key}>
                #{t(`storyTags.${key}`, { position: horizontalDisplayView?.position })}
              </span>
            );
          default:
            return null;
        }
      })}
      {isReadOnly && <span>#{t('common.readonly')}</span>}
      {isSync && <span>#{t('common.sync')}</span>}
      {allowAccessToEditorLimited && <span>#{t('roles.editor-limited')}</span>}
      {allowAccessToReader && <span>#{t('roles.reader')}</span>}
    </div>
  );
});
