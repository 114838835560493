import { useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../context';
import { StoryStatus } from '../../services';
import { getStories } from '../../slices';
import { STORY_TAB_ADMIN_COOKIE_KEY } from '../constants';
import { isAdminLayout } from '../utils';
import { useAppDispatch } from './useAppDispatch';

export const useStoriesFeed = () => {
  const dispatch = useAppDispatch();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [cookies] = useCookies([STORY_TAB_ADMIN_COOKIE_KEY]);

  const { requestFilter = undefined } = {
    ...cookies[STORY_TAB_ADMIN_COOKIE_KEY],
  };

  const filter = useMemo(() => {
    if (!isAdminLayout()) {
      return { onlyPostIn: false, status: [StoryStatus.PUBLISHED] };
    }
    return requestFilter;
  }, [requestFilter]);

  const getStoriesFeed = useCallback(() => {
    dispatch(getStories({ channelId, filter }));
  }, [channelId, dispatch, filter]);

  return { getStoriesFeed };
};
