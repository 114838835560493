import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '../../../context';
import { ChatAccess, ChatTypes } from '../../../services';
import { Avatar, Button, ButtonType } from '../../../shared';
import { CreateChatModal } from '../CreateChatModal';
import { AddChatMembers } from './AddChatMembers';
import { ManageChatMembers } from './ManageChatMembers';

import classNames from 'classnames';
import classes from './ChatDetails.module.scss';

export enum ChatDetailsType {
  INFO = 'INFO',
  ADD_USERS = 'ADD_USERS',
  MANAGE_USERS = 'MANAGE_USERS',
}

interface ChatDetailsProps {
  detailsType: ChatDetailsType | null;
  setDetailsType: (type: ChatDetailsType | null) => void;
}

export const ChatDetails: FunctionComponent<ChatDetailsProps> = memo(
  ({ detailsType, setDetailsType }) => {
    const { t } = useTranslation();

    const { activeChat } = useContext(ChatContext);

    const isOpen = useMemo(() => Boolean(detailsType), [detailsType]);

    const { type, access, usersCount, created, accessType, name, payload, image } = {
      ...activeChat,
    };

    const isGroupChat = useMemo(() => type === ChatTypes.group, [type]);

    const hasAdminAccess = useMemo(() => access === ChatAccess.admin, [access]);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

    const editChatHandler = useCallback(() => {
      setDetailsType(null);
      setIsCreateModalOpen(true);
    }, [setDetailsType]);

    const body = useMemo(() => {
      switch (detailsType) {
        case ChatDetailsType.ADD_USERS:
          return <AddChatMembers />;
        case ChatDetailsType.MANAGE_USERS:
          return <ManageChatMembers />;
        case ChatDetailsType.INFO:
          return (
            <div className={classes['chat-details__content-body']}>
              {isGroupChat && (
                <div className={classes['chat-details__content-body-group']}>
                  <Avatar size={64} url={image?.url} nonClickable />

                  {name && (
                    <div className={classes['chat-details__content-body-group-name']}>{name}</div>
                  )}

                  {payload && (
                    <div className={classes['chat-details__content-body-group-purpose']}>
                      {payload}
                    </div>
                  )}

                  {hasAdminAccess && (
                    <Button
                      type={ButtonType.secondary}
                      label={t('common.edit')}
                      onClick={editChatHandler}
                    />
                  )}
                </div>
              )}
              <div className={classes['chat-details__content-body-info']}>
                <div className={classes['chat-details__content-body-info-title']}>
                  {t('chatActions.info')}
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.created')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {format(parseISO(created ?? ''), 'dd.MM.yyyy, HH:mm')}
                  </div>
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.participants')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {usersCount}
                  </div>
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.access')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {t(`chatAccessTypes.${accessType}`)}
                  </div>
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.type')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {t(`chatTypes.${type}`)}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    }, [
      accessType,
      created,
      detailsType,
      editChatHandler,
      hasAdminAccess,
      image?.url,
      isGroupChat,
      name,
      payload,
      t,
      type,
      usersCount,
    ]);

    if (!activeChat) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(classes['chat-details'], {
            [classes['chat-details--open']]: isOpen,
          })}
        >
          <div
            className={classes['chat-details__overlay']}
            onClick={() => setDetailsType(null)}
          ></div>
          <div className={classes['chat-details__content']}>{body}</div>
        </div>

        {isCreateModalOpen && (
          <CreateChatModal
            chat={activeChat}
            isOpen={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
          />
        )}
      </>
    );
  }
);
