import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../../context';
import { User } from '../../../services';
import {
  Avatar,
  Button,
  ButtonType,
  IconLabel,
  LineClamp,
  LineClampProps,
  getCssVar,
} from '../../../shared';

import classes from './UserDirectoryItem.module.scss';

interface UserDirectoryItemProps {
  user: User;
}

export const UserDirectoryItem: FunctionComponent<UserDirectoryItemProps> = memo(({ user }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userProfile } = useContext(UserContext);

  const { config } = useContext(ConfigContext);

  const { fields } = config.elements.profile;

  const { id, avatar, email, phone, position, screenName } = user;

  const lineClampCommonProps = useMemo(() => {
    return { maxLines: 2, readMore: '...' } as LineClampProps;
  }, []);

  const openProfile = useCallback(() => {
    navigate(id === userProfile.id ? '/profile' : `/profile/${id}`);
  }, [id, navigate, userProfile.id]);

  const onEmailClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      window.open(`mailto:${email}`);
    },
    [email]
  );

  const onPhoneClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      window.open(`tel:${email}`);
    },
    [email]
  );

  const onProfileClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      openProfile();
    },
    [openProfile]
  );

  return (
    <div className={classes['user']} onClick={openProfile}>
      <Avatar url={avatar?.url} size={48} nonClickable />
      <div className={classes['user__info']}>
        <LineClamp
          {...lineClampCommonProps}
          stringContent={screenName}
          contentClassName={classes['user__info-name']}
          readMoreClassName={classes['user__info-name']}
        />
        <LineClamp
          {...lineClampCommonProps}
          stringContent={position}
          contentClassName={classes['user__info-position']}
          readMoreClassName={classes['user__info-position']}
        />
      </div>
      <div className={classes['user__links']}>
        {fields['email'].visible && email && (
          <IconLabel
            iconId={'email'}
            iconSize={40}
            color={getCssVar('--user-directory-link-color')}
            hoverColor={getCssVar('--user-directory-link-hover-color')}
            onClick={onEmailClick}
          />
        )}
        {fields['phone'].visible && phone && (
          <IconLabel
            iconId={'phone'}
            iconSize={40}
            color={getCssVar('--user-directory-link-color')}
            hoverColor={getCssVar('--user-directory-link-hover-color')}
            onClick={onPhoneClick}
          />
        )}
        <Button
          type={ButtonType.primary}
          label={t('profile.open-profile')}
          onClick={onProfileClick}
        />
      </div>
    </div>
  );
});
