import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import {
  OrganisationTag,
  RestrictDuplicateUrlOfCardsInTimeFrame,
  Story,
  StoryAccess,
  StoryAutoNotification,
  StoryCardLimit,
  StoryCardTeaserImageStyle,
  StoryCreateFieldsInput,
  StoryDisplayItemUpdatedTime,
  StoryHighlightingCommentsMode,
  StoryType,
  StoryUpdateArgs,
  storyApi,
} from '../../../services';
import {
  Button,
  ButtonType,
  CARD,
  CARD_LOCALE,
  CardProps,
  FileAcceptType,
  FilePreview,
  FileUpload,
  FilterValue,
  GalleryItem,
  IconLabelWithActive,
  Modal,
  TextAreaField,
  optionalArrayItem,
  useAppDispatch,
  useFilter,
  useRefreshFeeds,
} from '../../../shared';
import { updateStory } from '../../../slices';
import {
  AUTO_NOTIFICATION_MESSAGE_MIN_LIMIT,
  AUTO_NOTIFICATION_TITLE_MIN_LIMIT,
  CreateStorySettings,
  CreateStorySettingsTabType,
} from './CreateStorySettings';
import { StorySync, StorySyncSource } from './StorySync';

import 'react-loading-skeleton/dist/skeleton.css';
import classes from './CreateStoryModal.module.scss';

interface CreateStoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  story: Story;
}

export interface StorySettings {
  display: FilterValue[];
  card: FilterValue[];
  tags: {
    external: OrganisationTag[];
    setExternal: (items: OrganisationTag[]) => void;
    internal: OrganisationTag[];
    setInternal: (items: OrganisationTag[]) => void;
  };
  media: {
    galleryItems: GalleryItem[];
    setGalleryItems: Dispatch<SetStateAction<GalleryItem[]>>;
    activeGalleryItemIndex: number;
  };
  access: FilterValue[];
  other: FilterValue[];
  autoNotification: {
    autoNotification: StoryAutoNotification;
    setAutoNotification: Dispatch<SetStateAction<StoryAutoNotification>>;
  };
  sync: {
    source: StorySyncSource;
    setSource: Dispatch<SetStateAction<StorySyncSource>>;
    setTitle: Dispatch<SetStateAction<string>>;
    setSubtitle: Dispatch<SetStateAction<string>>;
    setGalleryItems: Dispatch<SetStateAction<GalleryItem[]>>;
  };
}

export const DISPLAY = 'display';

export enum DisplayProps {
  includeInWebappNewsFeed = 'includeInWebappNewsFeed',
  includeInNewsFeed = 'includeInNewsFeed',
  includeInMixTab = 'includeInMixTab',
  includeInMixTab2 = 'includeInMixTab2',
  pinned = 'pinned',
  horizontalPosition = 'position',
}

export const CARD_COMMENTS = 'highlightingComments';

export const CARD_TEASER = 'teaserStyle';

export const ACCESS = 'access';

export const OTHER = 'other';

export enum OtherProps {
  shouldSyncItemPositionsOnConnectedStories = 'shouldSyncItemPositionsOnConnectedStories',
}

export const OTHER_TIME = 'displayItemUpdatedTime';

export const OTHER_DUPLICATES = 'shouldRestrictDuplicateUrlOfCardsInTimeFrame';

export const OTHER_CARD_LIMIT = 'cardLimit';

export const CreateStoryModal: FunctionComponent<CreateStoryModalProps> = memo(
  ({ isOpen, onClose, story }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { refreshFeeds } = useRefreshFeeds({ scrollTop: false });

    const {
      id: storyId,
      type: storyType,
      title: storyTitle,
      subtitle: storySubtitle,
      pinned: storyPinned,
      image: storyImage,
      settings: storySettings,
      localeId: storyLocaleId,
      externalTags: storyExternalTags,
      internalTags: storyInternalTags,
      sources: storySources,
    } = story;

    const {
      allowCardReactions: storyAllowCardReactions,
      allowComments: storyAllowComments,
      allowSharing: storyAllowSharing,
      autoNotification: storyAutoNotification,
      allowAccessToReader: storyAllowAccessToReader,
      allowAccessToEditorLimited: storyAllowAccessToEditorLimited,
      includeInWebappNewsFeed: storyIncludeInWebappNewsFeed,
      includeInNewsFeed: storyIncludeInNewsFeed,
      includeInMixTab: storyIncludeInMixTab,
      includeInMixTab2: storyIncludeInMixTab2,
      horizontalDisplayView: storyHorizontalDisplayView,
      shouldUpdatePostedTimeByComments: storyShouldUpdatePostedTimeByComments,
      highlightingComments: storyHighlightingComments,
      displayItemUpdatedTime: storyDisplayItemUpdatedTime,
      cardStyles: storyCardStyles,
      showAllAuthors: storyShowAllAuthors,
      shouldSyncItemPositionsOnConnectedStories: storyShouldSyncItemPositionsOnConnectedStories,
      cardLimit: storyCardLimit,
      shouldRestrictDuplicateUrlOfCardsInTimeFrame:
        storyShouldRestrictDuplicateUrlOfCardsInTimeFrame,
    } = { ...storySettings };

    const [storyCreateTrigger] = storyApi.endpoints.storyCreate.useLazyQuery();

    const { channelId, channelRestrictDuplicateUrlOfCardsGQL } =
      useContext(UserContext).userInfo.userData;

    const editMode = useMemo(() => Boolean(storyId), [storyId]);

    const isStandard = useMemo(() => storyType === StoryType.STANDARD, [storyType]);

    const standardOrEdit = useMemo(() => isStandard || editMode, [editMode, isStandard]);

    const noStandardNorEdit = useMemo(() => !isStandard && !editMode, [editMode, isStandard]);

    const [galleryItems, setGalleryItems] = useState<GalleryItem[]>(
      storyImage ? [{ image: storyImage }] : []
    );

    const [activeGalleryItemIndex, setActiveGalleryItemIndex] = useState(0);

    const [activeSettingsTabIndex, setActiveSettingsTabIndex] = useState(0);

    const galleryItemImage = useMemo(() => galleryItems[0]?.image, [galleryItems]);

    const [externalTags, setExternalTags] = useState<OrganisationTag[]>(storyExternalTags ?? []);

    const [internalTags, setInternalTags] = useState<OrganisationTag[]>(storyInternalTags ?? []);

    const [autoNotification, setAutoNotification] = useState<StoryAutoNotification>(
      storyAutoNotification ?? {
        enabled: false,
        titleDefault: '',
        messageDefault: '',
        titlePrefix: '',
      }
    );

    const [source, setSource] = useState<StorySyncSource>({
      enabled: !isStandard || Boolean(storySources?.length),
      channelsId: (storySources ?? []).map(({ channel }) => channel.id),
      storiesId: (storySources ?? []).map(({ story }) => story.id),
      fetchLastCards: 0,
    });

    const [title, setTitle] = useState<string>(editMode ? storyTitle : '');

    const [subtitle, setSubtitle] = useState<string>(editMode ? storySubtitle : '');

    const getDisplayItems = useMemo(() => {
      if (!editMode) {
        return [
          { id: DisplayProps.includeInWebappNewsFeed, value: DisplayProps.includeInWebappNewsFeed },
          { id: DisplayProps.includeInNewsFeed, value: DisplayProps.includeInNewsFeed },
          { id: DisplayProps.includeInMixTab, value: DisplayProps.includeInMixTab },
        ];
      }

      return [
        ...optionalArrayItem(storyIncludeInWebappNewsFeed, {
          id: DisplayProps.includeInWebappNewsFeed,
          value: DisplayProps.includeInWebappNewsFeed,
        }),
        ...optionalArrayItem(storyIncludeInNewsFeed, {
          id: DisplayProps.includeInNewsFeed,
          value: DisplayProps.includeInNewsFeed,
        }),
        ...optionalArrayItem(storyIncludeInMixTab, {
          id: DisplayProps.includeInMixTab,
          value: DisplayProps.includeInMixTab,
        }),
        ...optionalArrayItem(storyIncludeInMixTab2, {
          id: DisplayProps.includeInMixTab2,
          value: DisplayProps.includeInMixTab2,
        }),
        ...optionalArrayItem(Boolean(storyPinned), {
          id: DisplayProps.pinned,
          value: storyPinned,
        }),
        ...optionalArrayItem(Boolean(storyHorizontalDisplayView), {
          id: DisplayProps.horizontalPosition,
          value: storyHorizontalDisplayView?.position,
        }),
      ];
    }, [
      editMode,
      storyHorizontalDisplayView,
      storyIncludeInMixTab,
      storyIncludeInMixTab2,
      storyIncludeInNewsFeed,
      storyIncludeInWebappNewsFeed,
      storyPinned,
    ]);

    const getCardItems = useMemo(() => {
      if (!editMode) {
        return [
          { id: CardProps.allowComments, value: CardProps.allowComments },
          { id: CardProps.allowCardReactions, value: CardProps.allowCardReactions },
        ];
      }

      return [
        ...optionalArrayItem(storyAllowComments, {
          id: CardProps.allowComments,
          value: CardProps.allowComments,
        }),
        ...optionalArrayItem(storyAllowCardReactions, {
          id: CardProps.allowCardReactions,
          value: CardProps.allowCardReactions,
        }),
        ...optionalArrayItem(storyAllowSharing, {
          id: CardProps.allowSharing,
          value: CardProps.allowSharing,
        }),
        ...optionalArrayItem(storyShouldUpdatePostedTimeByComments, {
          id: CardProps.shouldUpdatePostedTimeByComments,
          value: CardProps.shouldUpdatePostedTimeByComments,
        }),
        ...optionalArrayItem(storyShowAllAuthors, {
          id: CardProps.showAllAuthors,
          value: CardProps.showAllAuthors,
        }),
      ];
    }, [
      editMode,
      storyAllowCardReactions,
      storyAllowComments,
      storyAllowSharing,
      storyShouldUpdatePostedTimeByComments,
      storyShowAllAuthors,
    ]);

    const getCardCommentItems = useMemo(() => {
      const value = editMode
        ? storyHighlightingComments
        : StoryHighlightingCommentsMode.MOST_LIKEABLE;

      return [
        { id: StoryHighlightingCommentsMode[value], value: StoryHighlightingCommentsMode[value] },
      ];
    }, [editMode, storyHighlightingComments]);

    const getCardTeaserItems = useMemo(() => {
      const value = editMode
        ? storyCardStyles?.article?.teaserStyle
        : StoryCardTeaserImageStyle.STANDARD;

      return [{ id: StoryCardTeaserImageStyle[value], value: StoryCardTeaserImageStyle[value] }];
    }, [editMode, storyCardStyles?.article?.teaserStyle]);

    const getAccessItems = useMemo(() => {
      if (!editMode) {
        return [];
      }

      return [
        ...optionalArrayItem(storyAllowAccessToEditorLimited, {
          id: StoryAccess.EDITOR_LIMITED,
          value: StoryAccess.EDITOR_LIMITED,
        }),
        ...optionalArrayItem(storyAllowAccessToReader, {
          id: StoryAccess.READER,
          value: StoryAccess.READER,
        }),
      ];
    }, [editMode, storyAllowAccessToEditorLimited, storyAllowAccessToReader]);

    const getOtherItems = useMemo(() => {
      if (!editMode) {
        return [];
      }

      return [
        ...optionalArrayItem(storyShouldSyncItemPositionsOnConnectedStories, {
          id: OtherProps.shouldSyncItemPositionsOnConnectedStories,
          value: OtherProps.shouldSyncItemPositionsOnConnectedStories,
        }),
      ];
    }, [editMode, storyShouldSyncItemPositionsOnConnectedStories]);

    const getOtherTimeItems = useMemo(() => {
      const value = editMode
        ? storyDisplayItemUpdatedTime
        : StoryDisplayItemUpdatedTime.POSTED_TIME;

      return [
        { id: StoryDisplayItemUpdatedTime[value], value: StoryDisplayItemUpdatedTime[value] },
      ];
    }, [editMode, storyDisplayItemUpdatedTime]);

    const restrictDuplicateDisabled = useMemo(
      () => channelRestrictDuplicateUrlOfCardsGQL !== RestrictDuplicateUrlOfCardsInTimeFrame.NEVER,
      [channelRestrictDuplicateUrlOfCardsGQL]
    );

    const restrictDuplicateValue = useMemo(() => {
      if (restrictDuplicateDisabled) {
        return [
          { value: RestrictDuplicateUrlOfCardsInTimeFrame[channelRestrictDuplicateUrlOfCardsGQL] },
        ];
      }

      return [
        {
          value:
            RestrictDuplicateUrlOfCardsInTimeFrame[
              storyShouldRestrictDuplicateUrlOfCardsInTimeFrame
            ] ?? RestrictDuplicateUrlOfCardsInTimeFrame.NEVER,
        },
      ];
    }, [
      channelRestrictDuplicateUrlOfCardsGQL,
      restrictDuplicateDisabled,
      storyShouldRestrictDuplicateUrlOfCardsInTimeFrame,
    ]);

    const cardLimitValue = useMemo(() => {
      return [{ value: StoryCardLimit[storyCardLimit] ?? StoryCardLimit.ONE_THOUSAND }];
    }, [storyCardLimit]);

    const [settings, setSettings] = useState<StorySettings>({
      display: [{ groupId: DISPLAY, groupItems: getDisplayItems }],
      card: [
        ...optionalArrayItem(isStandard, {
          groupId: CARD,
          groupItems: getCardItems,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: CARD_COMMENTS,
          groupItems: getCardCommentItems,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: CARD_TEASER,
          groupItems: getCardTeaserItems,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: CARD_LOCALE,
          groupItems: [{ value: storyLocaleId ?? 1 }],
        }),
      ],
      tags: {
        external: externalTags,
        setExternal: setExternalTags,
        internal: internalTags,
        setInternal: setInternalTags,
      },
      media: { galleryItems, setGalleryItems, activeGalleryItemIndex },
      access: [
        ...optionalArrayItem(isStandard, {
          groupId: ACCESS,
          groupItems: getAccessItems,
        }),
      ],
      other: [
        ...optionalArrayItem(isStandard, {
          groupId: OTHER,
          groupItems: getOtherItems,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: OTHER_TIME,
          groupItems: getOtherTimeItems,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: OTHER_DUPLICATES,
          groupItems: restrictDuplicateValue,
        }),
        ...optionalArrayItem(isStandard, {
          groupId: OTHER_CARD_LIMIT,
          groupItems: cardLimitValue,
        }),
      ],
      autoNotification: { autoNotification, setAutoNotification },
      sync: { source, setSource, setTitle, setSubtitle, setGalleryItems },
    });

    useEffect(() => {
      setSettings({
        ...settings,
        tags: {
          external: externalTags,
          setExternal: setExternalTags,
          internal: internalTags,
          setInternal: setInternalTags,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalTags, internalTags]);

    useEffect(() => {
      setSettings({
        ...settings,
        media: { galleryItems, setGalleryItems, activeGalleryItemIndex },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryItems, activeGalleryItemIndex]);

    useEffect(() => {
      setSettings({ ...settings, autoNotification: { autoNotification, setAutoNotification } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoNotification]);

    useEffect(() => {
      setSettings({
        ...settings,
        sync: { source, setSource, setTitle, setSubtitle, setGalleryItems },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const { getGroupValue, getGroupItemValue } = useFilter();

    const { display, card, access, other } = settings;

    const [loading, setLoading] = useState<boolean>(false);

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

    const isAutoNotificationValid = useMemo(() => {
      const { enabled, titleDefault, messageDefault } = autoNotification;

      if (!enabled) {
        return true;
      }

      return Boolean(
        titleDefault.length >= AUTO_NOTIFICATION_TITLE_MIN_LIMIT &&
          messageDefault.length >= AUTO_NOTIFICATION_MESSAGE_MIN_LIMIT
      );
    }, [autoNotification]);

    const saveDisabled = useMemo(() => {
      if (loading || !title.length || !isAutoNotificationValid) {
        return true;
      }

      if (noStandardNorEdit) {
        return Boolean(!source?.storiesId?.length);
      }

      return false;
    }, [
      isAutoNotificationValid,
      loading,
      noStandardNorEdit,
      source?.storiesId?.length,
      title.length,
    ]);

    const toggleStoryDisable = useCallback(
      ({ disabled }: { disabled: boolean }) => {
        if (!editMode) {
          return;
        }

        document.querySelectorAll(`.story-${storyId}`).forEach((storyElement) => {
          disabled
            ? storyElement.classList.add('story-disabled')
            : storyElement.classList.remove('story-disabled');
        });
      },
      [editMode, storyId]
    );

    const saveStory = useCallback(async () => {
      toggleStoryDisable({ disabled: true });
      onClose();

      const includeInWebappNewsFeed = Boolean(
        getGroupItemValue({
          filter: display,
          groupId: DISPLAY,
          itemId: DisplayProps.includeInWebappNewsFeed,
        })
      );

      const includeInNewsFeed = Boolean(
        getGroupItemValue({
          filter: display,
          groupId: DISPLAY,
          itemId: DisplayProps.includeInNewsFeed,
        })
      );

      const includeInMixTab = Boolean(
        getGroupItemValue({
          filter: display,
          groupId: DISPLAY,
          itemId: DisplayProps.includeInMixTab,
        })
      );

      const includeInMixTab2 = Boolean(
        getGroupItemValue({
          filter: display,
          groupId: DISPLAY,
          itemId: DisplayProps.includeInMixTab2,
        })
      );

      const pinned =
        getGroupItemValue({ filter: display, groupId: DISPLAY, itemId: DisplayProps.pinned }) ??
        null;

      const position = getGroupItemValue({
        filter: display,
        groupId: DISPLAY,
        itemId: DisplayProps.horizontalPosition,
      });

      const allowComments = Boolean(
        getGroupItemValue({ filter: card, groupId: CARD, itemId: CardProps.allowComments })
      );

      const allowCardReactions = Boolean(
        getGroupItemValue({ filter: card, groupId: CARD, itemId: CardProps.allowCardReactions })
      );

      const allowSharing = Boolean(
        getGroupItemValue({ filter: card, groupId: CARD, itemId: CardProps.allowSharing })
      );

      const shouldUpdatePostedTimeByComments = Boolean(
        getGroupItemValue({
          filter: card,
          groupId: CARD,
          itemId: CardProps.shouldUpdatePostedTimeByComments,
        })
      );

      const showAllAuthors = Boolean(
        getGroupItemValue({ filter: card, groupId: CARD, itemId: CardProps.showAllAuthors })
      );

      const localeId = getGroupValue({ filter: card, groupId: CARD_LOCALE });

      const highlightingComments = getGroupValue({ filter: card, groupId: CARD_COMMENTS });

      const teaserStyle = getGroupValue({ filter: card, groupId: CARD_TEASER });

      const accessValue = getGroupValue({ filter: access, groupId: ACCESS, multiCheck: true });

      const shouldSyncItemPositionsOnConnectedStories = Boolean(
        getGroupValue({ filter: other, groupId: OTHER })
      );

      const displayItemUpdatedTime = getGroupValue({ filter: other, groupId: OTHER_TIME });

      const shouldRestrictDuplicateUrlOfCardsInTimeFrame = getGroupValue({
        filter: other,
        groupId: OTHER_DUPLICATES,
      });

      const cardLimit = getGroupValue({ filter: other, groupId: OTHER_CARD_LIMIT });

      const fields: StoryCreateFieldsInput = {
        title,
        subtitle,
        pinned,
        imageId: galleryItemImage?.id ?? null,
        imageRightholder: galleryItemImage?.rightholder ?? '',
        ...(isStandard && {
          localeId,
          organisationTags: {
            externalTagsId: externalTags.map(({ id }) => id),
            internalTagsId: internalTags.map(({ id }) => id),
          },
        }),
        source: {
          ...(!editMode && { isReadOnly: !isStandard, fetchLastCards: source.fetchLastCards }),
          storiesId: source.enabled ? source.storiesId : [],
        },
        settings: {
          includeInWebappNewsFeed,
          includeInNewsFeed,
          includeInMixTab,
          includeInMixTab2,
          horizontalDisplayView: position ? { position } : null,
          ...(isStandard && {
            allowComments,
            allowCardReactions,
            allowSharing,
            shouldUpdatePostedTimeByComments,
            showAllAuthors,
            autoNotification,
            cardLimit,
            highlightingComments,
            cardStyles: { article: { teaserStyle } },
            access: accessValue,
            shouldSyncItemPositionsOnConnectedStories,
            displayItemUpdatedTime,
            shouldRestrictDuplicateUrlOfCardsInTimeFrame,
          }),
        },
      };

      const storyCreateArgs = { channelId, fields };
      const storyUpdateArgs = { storyId, fields } as StoryUpdateArgs;

      const action = editMode ? 'edit' : 'create';

      try {
        const { id: storyId } = await toast.promise(
          (editMode
            ? dispatch(updateStory(storyUpdateArgs))
            : storyCreateTrigger(storyCreateArgs)
          ).unwrap(),
          {
            pending: t(`addStory.${action}-pending`),
            success: t(`addStory.${action}-success`),
          }
        );

        if (!storyId) {
          return;
        }

        refreshFeeds();
      } catch (_) {
        toast.error(t(`addStory.${action}-error`));
      } finally {
        toggleStoryDisable({ disabled: false });
      }
    }, [
      access,
      autoNotification,
      card,
      channelId,
      dispatch,
      display,
      editMode,
      externalTags,
      galleryItemImage?.id,
      galleryItemImage?.rightholder,
      getGroupItemValue,
      getGroupValue,
      internalTags,
      isStandard,
      onClose,
      other,
      refreshFeeds,
      source,
      storyCreateTrigger,
      storyId,
      subtitle,
      t,
      title,
      toggleStoryDisable,
    ]);

    const readOnlyContent = useMemo(() => {
      if (standardOrEdit) {
        return null;
      }

      return (
        <>
          <div className={classes['create-story__subTitle']}>
            {t('createStory.modal-sub-title')}
          </div>

          <StorySync
            source={source}
            setSource={setSource}
            setTitle={setTitle}
            setSubtitle={setSubtitle}
            setGalleryItems={setGalleryItems}
          />
        </>
      );
    }, [source, standardOrEdit, t]);

    const body = useMemo(() => {
      return (
        <div className={classes['create-story']}>
          <div className={classes['create-story__title']}>
            {t(`${editMode ? 'update' : 'create'}Story.modal-title`, {
              type: t(`common.${storyType.toLowerCase()}`).toLowerCase(),
            })}
          </div>
          {readOnlyContent}
          <div className={classes['create-story__content']}>
            <FileUpload
              galleryItems={galleryItems}
              setGalleryItems={setGalleryItems}
              loading={loading}
              setLoading={setLoading}
              fileAcceptTypes={[FileAcceptType.IMAGE]}
              uploadLimit={1}
            />

            <FilePreview
              galleryItems={galleryItems}
              setGalleryItems={setGalleryItems}
              activeGalleryItemIndex={activeGalleryItemIndex}
              setActiveGalleryItemIndex={setActiveGalleryItemIndex}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
              activeMediaTab={activeSettingsTabIndex === CreateStorySettingsTabType.MEDIA}
              setActiveMediaTab={(isActive) =>
                setActiveSettingsTabIndex(
                  isActive ? CreateStorySettingsTabType.MEDIA : CreateStorySettingsTabType.DISPLAY
                )
              }
            />

            <div className={classes['create-story__content-fields']}>
              <TextAreaField
                inputClassName={classes['create-story__content-fields-title']}
                value={title}
                placeholder={t('createStory.title-placeholder')}
                maxLength={160}
                onChange={({ target }) => setTitle(target.value)}
                disableLineBreak
              />

              <TextAreaField
                inputClassName={classes['create-story__content-fields-intro']}
                value={subtitle}
                placeholder={t('createStory.subtitle-placeholder')}
                maxLength={160}
                onChange={({ target }) => setSubtitle(target.value)}
                disableLineBreak
              />
            </div>
          </div>
          <div className={classes['create-story__buttons']}>
            <IconLabelWithActive
              active={settingsOpen}
              setActive={setSettingsOpen}
              label={t('common.settings')}
              iconId={'settings'}
              className={classes['create-story__buttons-settings']}
            />

            <Button type={ButtonType.secondary} label={t('common.cancel')} onClick={onClose} />

            <Button
              type={ButtonType.primary}
              label={t(`common.${editMode ? 'update' : 'create'}`)}
              onClick={saveStory}
              disabled={saveDisabled}
            />
          </div>

          {settingsOpen && (
            <CreateStorySettings
              storyId={storyId}
              settings={settings}
              onChange={setSettings}
              isStandard={isStandard}
              activeSettingsTabIndex={activeSettingsTabIndex}
              setActiveSettingsTabIndex={setActiveSettingsTabIndex}
            />
          )}
        </div>
      );
    }, [
      activeGalleryItemIndex,
      activeSettingsTabIndex,
      editMode,
      galleryItems,
      isStandard,
      loading,
      onClose,
      readOnlyContent,
      saveDisabled,
      saveStory,
      settings,
      settingsOpen,
      storyId,
      storyType,
      subtitle,
      t,
      title,
    ]);

    return (
      <>
        {isOpen &&
          ReactDOM.createPortal(
            <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />,
            document.getElementById('modal-root') as HTMLElement
          )}
      </>
    );
  }
);
