/* eslint-disable @typescript-eslint/no-explicit-any */

import { subDays, subMonths } from 'date-fns';
import { useContext } from 'react';
import { ConfigContext } from '../../context';

export enum LastDateValue {
  LAST_24_HRS = 'LAST_24_HRS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  ALL_TIME = 'ALL_TIME',
}

export const useLastDate = () => {
  const { allTimeStartDate } = useContext(ConfigContext).config.elements.dateRangeFilter;

  const isLastDate = (value: any) => Boolean(Object.values(LastDateValue).includes(value));

  const getLastDate = (value: LastDateValue) => {
    const date = new Date();

    date.setSeconds(0, 0);

    const to = date.toISOString();

    switch (value) {
      case LastDateValue.LAST_24_HRS:
        return { from: subDays(date, 1).toISOString(), to };
      case LastDateValue.LAST_7_DAYS:
        return { from: subDays(date, 7).toISOString(), to };
      case LastDateValue.LAST_30_DAYS:
        return { from: subDays(date, 30).toISOString(), to };
      case LastDateValue.LAST_3_MONTHS:
        return { from: subMonths(date, 3).toISOString(), to };
      case LastDateValue.LAST_6_MONTHS:
        return { from: subMonths(date, 6).toISOString(), to };
      case LastDateValue.LAST_12_MONTHS:
        return { from: subMonths(date, 12).toISOString(), to };
      case LastDateValue.ALL_TIME:
        return { from: new Date(allTimeStartDate).toISOString(), to };
      default:
        return { from: null, to: null };
    }
  };

  return { isLastDate, getLastDate };
};
