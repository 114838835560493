import { ElementType, FunctionComponent, memo, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useSearchParams } from 'react-router-dom';
import sprite from '../../../assets/icons/sprite.svg';
import { PostedIn } from '../../../components';
import { useAppSelector, useSearchFeed } from '../../hooks';
import { FeedCardLoader, FeedCardProps } from '../FeedCard';

import classes from './SearchFeedCommon.module.scss';

interface SearchFeedCommonProps {
  CardComponent: ElementType<FeedCardProps>;
}

export const SearchFeedCommon: FunctionComponent<SearchFeedCommonProps> = memo(
  ({ CardComponent }) => {
    const { t } = useTranslation();

    const { getSearchFeedItems, loadMoreSearchFeedItems } = useSearchFeed();

    const [searchParams] = useSearchParams();

    const query = searchParams.get('qs') ?? '';

    const { searchFeed, totalItems, hasNextPage, isFetching } = useAppSelector(
      ({ searchFeed }) => searchFeed
    );

    const hasCards = useMemo(() => Boolean(searchFeed.length), [searchFeed.length]);

    useEffect(() => {
      getSearchFeedItems();
    }, [getSearchFeedItems]);

    const content = useMemo(() => {
      if (!hasCards) {
        return (
          <div className={classes['search__no-result']}>
            <svg className={classes['search__no-result-icon']}>
              <use href={`${sprite}#search-off`} />
            </svg>
            <div className={classes['search__no-result-text']}>
              <Trans i18nKey={'search.no-results'}>
                No results match that query
                <Link className={classes['search__no-result-link']} to={'/admin'}>
                  Home
                </Link>
              </Trans>
            </div>
          </div>
        );
      }

      return (
        <InfiniteScroll
          className={classes['search__feed']}
          next={loadMoreSearchFeedItems}
          hasMore={hasNextPage}
          loader={<FeedCardLoader />}
          dataLength={searchFeed.length}
        >
          <div className={classes['search__feed-cards']}>
            {searchFeed.map((card, index, searchFeedArray) => {
              return (
                <div key={`story-card-item-${card.id}`}>
                  {card.story.title !== searchFeedArray[index - 1]?.story.title && (
                    <PostedIn story={card.story} />
                  )}
                  <CardComponent card={card} />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      );
    }, [CardComponent, hasCards, hasNextPage, loadMoreSearchFeedItems, searchFeed]);

    if (isFetching) {
      return <FeedCardLoader />;
    }

    return (
      <>
        <div className={classes['search__title']}>
          {t('search.results-for')}&nbsp;
          <span className={classes['search__title-query']}>{query}</span>
        </div>
        <div className={classes['search__count']}>
          {t('search.results-found', { count: totalItems })}
        </div>
        {content}
      </>
    );
  }
);
