import { StoryCard } from '../../services';
import { ReactionsCount } from '../../shared';

export const reactOnCardReducer = ({
  card,
  reaction,
  reactions,
}: {
  card: StoryCard;
  reaction: string;
  reactions: ReactionsCount[];
}) => {
  card.myReaction = card.myReaction === reaction ? '' : reaction;
  card.reactions = reactions;
};
