import { FunctionComponent, memo, useContext, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Emoticon } from '../../../assets/emojis';
import { ConfigContext } from '../../../context';
import { Emoji, Emojis, ReactionsCount } from '../../models';

import classNames from 'classnames';
import classes from './ReactionsCounter.module.scss';

interface ReactionsCounterProps {
  reactions: ReactionsCount[];
  myReaction: string;
  totalCount?: number;
  reactionSize?: number;
  individualOnly?: boolean;
  className?: string;
}

export const ReactionsCounter: FunctionComponent<ReactionsCounterProps> = memo(
  ({ reactions, myReaction, totalCount = 0, individualOnly, reactionSize, className }) => {
    const { t } = useTranslation();

    const { individualReaction } = useContext(ConfigContext).config.elements.card;

    const [showIndividual, setShowIndividual] = useState(individualOnly ?? individualReaction);

    const iconSize = useMemo(() => reactionSize ?? (isMobileOnly ? 24 : 30), [reactionSize]);

    const getReactions = useMemo(() => {
      return Object.values(reactions)
        .filter(({ count }) => Boolean(count))
        .sort((a, b) => {
          if (b.count === a.count) {
            return Emojis[a.name as Emoji] - Emojis[b.name as Emoji];
          }
          return b.count - a.count;
        });
    }, [reactions]);

    return (
      <div
        className={classNames(
          classes['counter'],
          { [classes['counter--clickable']]: !individualOnly },
          className
        )}
        onClick={() => !individualOnly && setShowIndividual(!showIndividual)}
      >
        <div className={classes['counter__reactions']}>
          {getReactions.map(({ name, count }) => (
            <div
              key={name}
              className={classNames(classes['counter__reactions-icon'], {
                [classes['counter__reactions-icon--individual']]: showIndividual,
              })}
            >
              <Emoticon reaction={name as Emoji} size={iconSize} />
              {showIndividual && (
                <span
                  className={classNames(classes['counter__reactions-icon-count'], {
                    [classes['counter__reactions-icon-count--mine']]: myReaction === name,
                  })}
                >
                  {count}
                </span>
              )}
            </div>
          ))}
        </div>
        {!showIndividual && (
          <div className={classes['counter__label']}>
            {myReaction
              ? t('reactions.counter-personal', { count: totalCount - 1 })
              : t('reactions.counter', { count: totalCount })}
          </div>
        )}
      </div>
    );
  }
);
