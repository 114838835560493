import { Comment, CommentsReactions } from '../services';
import { OrderDirection, PaginationInfo, ReactionsCount } from '../shared';
import { Channel } from './channelsNew';
import { graphqlApi } from './graphqlApi';
import { InAppActiveMessage } from './inAppMessage';
import { NotificationDefault } from './notifications';
import { Story } from './story';
import { StoryCard, StoryCardType } from './storyCard';

export enum AnalyticChartInterval {
  AUTO = 'AUTO',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  HOUR_OF_DAY = 'HOUR_OF_DAY',
}

export enum AnalyticChartIntervalResponse {
  DAY = 'DAY',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  HOUR = 'HOUR',
  HOUR_OF_DAY = 'HOUR_OF_DAY',
  MONTH = 'MONTH',
}

export enum AnalyticChartPlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum AnalyticContentListOrderBy {
  COMMENTS = 'COMMENTS',
  COMMENT_REACTIONS = 'COMMENT_REACTIONS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PLAYS = 'PLAYS',
  PLAY_DURATION = 'PLAY_DURATION',
  PLAY_DURATION_AVG = 'PLAY_DURATION_AVG',
  POSTED_TIME = 'POSTED_TIME',
  REACTIONS = 'REACTIONS',
  REACTIONS_NEGATIVE = 'REACTIONS_NEGATIVE',
  REACTIONS_POSITIVE = 'REACTIONS_POSITIVE',
  REACTION_ANGRY = 'REACTION_ANGRY',
  REACTION_HAHA = 'REACTION_HAHA',
  REACTION_LIKE = 'REACTION_LIKE',
  REACTION_LOVE = 'REACTION_LOVE',
  REACTION_SAD = 'REACTION_SAD',
  REACTION_WOW = 'REACTION_WOW',
  SHARES = 'SHARES',
  VIEWS = 'VIEWS',
  ENGAGEMENT = 'ENGAGEMENT',
}

export enum NotificationListOrderBy {
  DELIVERY_TIME = 'DELIVERY_TIME',
  OPEN = 'OPEN',
  SENT = 'SENT',
}

export enum AnalyticChartStoryListOrderBy {
  DURATION = 'DURATION',
  DURATION_AVG = 'DURATION_AVG',
  VIEWS = 'VIEWS',
}

interface FilterArgs {
  from: string;
  to: string;
  channelId?: number[];
  platform?: AnalyticChartPlatform[];
  organisationTags?: {
    externalTagsId: number[] | null;
    internalTagsId: number[] | null;
  };
}

interface FilterArgsWithInterval extends FilterArgs {
  interval?: AnalyticChartInterval;
}

interface TimeSeriesDataset {
  data: number[];
  label: string;
}

export interface ChartTimeSeries {
  datasets: TimeSeriesDataset[];
  interval: AnalyticChartIntervalResponse | null;
  intervals: string[];
  labels: string[];
}

interface AnalyticContentInteractionTableDataset {
  label: string;
  value: number;
  payload: {
    published: number;
    interacted: number;
  };
}

interface AnalyticContentInteractionTableSeries {
  items: AnalyticContentInteractionTableDataset[];
  pageInfo: PaginationInfo;
}

interface ContentListArgs extends FilterArgs {
  storyCardType: StoryCardType[];
  orderBy: AnalyticContentListOrderBy;
  orderDirection: OrderDirection;
  page?: number;
  size?: number;
}

interface AnalyticContentListTableDatasetPayload {
  channel: Channel;
  commentReactions: CommentsReactions[];
  commentReactionsTotal: number;
  comments: number;
  notifications: number;
  reactions: ReactionsCount[];
  reactionsTotal: number;
  reactionsPositive: number;
  reactionsNegative: number;
  shares: number;
  storyCard: StoryCard;
  views: number;
  engagement: number;
  plays: number;
  playDuration: number;
  playDurationAvg: number;
}

export interface AnalyticContentListTableDataset {
  label: string;
  value: number;
  payload: AnalyticContentListTableDatasetPayload;
}

interface AnalyticContentListTableSeries {
  items: AnalyticContentListTableDataset[];
  pageInfo: PaginationInfo;
}

interface CommentListArgs extends FilterArgs {
  orderDirection: OrderDirection;
  page?: number;
  size?: number;
}

interface AnalyticCommentListTableDatasetPayload {
  channel: Channel;
  comment: Comment;
  commentId: number;
  commentReactions: CommentsReactions[];
  commentReactionsTotal: number;
  storyCardTitle: string;
}

interface AnalyticCommentListTableDataset {
  label: string;
  value: number;
  payload: AnalyticCommentListTableDatasetPayload;
}

interface AnalyticCommentListTableSeries {
  items: AnalyticCommentListTableDataset[];
  pageInfo: PaginationInfo;
}

interface AnalyticChannelListTableDataset {
  label: string;
  payload: Channel;
  value: number;
}

interface AnalyticChannelListTableSeries {
  items: AnalyticChannelListTableDataset[];
  pageInfo: PaginationInfo;
}

interface AnalyticUserInteractionTableDataset {
  label: string;
  value: number;
}

interface AnalyticUserInteractionTableSeries {
  items: AnalyticUserInteractionTableDataset[];
  pageInfo: PaginationInfo;
}

interface AnalyticUserRoleContentTableDataset {
  label: string;
  value: number;
}

interface AnalyticUserRoleContentTableSeries {
  items: AnalyticUserRoleContentTableDataset[];
  pageInfo: PaginationInfo;
}

interface NotificationListArgs extends FilterArgs {
  orderBy: NotificationListOrderBy;
  orderDirection: OrderDirection;
  page?: number;
  size?: number;
}

export interface AnalyticPushNotificationListTableDatasetPayload {
  channel: Channel | null;
  notification: NotificationDefault;
  open: number;
  sent: number;
}

interface AnalyticPushNotificationListTableDataset {
  label: string;
  value: number;
  payload: AnalyticPushNotificationListTableDatasetPayload;
}

export interface AnalyticPushNotificationListTableSeries {
  items: AnalyticPushNotificationListTableDataset[];
  pageInfo: PaginationInfo;
}

export interface AnalyticInAppMessageTableDatasetPayload {
  channel: Channel | null;
  inAppMessage: InAppActiveMessage;
  open: number;
  sent: number;
}

interface AnalyticInAppMessageListTableDataset {
  label: string;
  value: number;
  payload: AnalyticInAppMessageTableDatasetPayload;
}

export interface AnalyticInAppMessageListTableSeries {
  items: AnalyticInAppMessageListTableDataset[];
  pageInfo: PaginationInfo;
}

interface AnalyticUserFunnelTableDataset {
  label: string;
  value: number;
}

interface AnalyticUserFunnelTableSeries {
  items: AnalyticUserFunnelTableDataset[];
  pageInfo: PaginationInfo;
}

interface AnalyticStoryListTableDatasetPayload {
  channel: Channel;
  duration: number;
  durationAvg: number;
  story: Story;
  views: number;
}

interface AnalyticStoryListTableDataset {
  label: string;
  value: number;
  payload: AnalyticStoryListTableDatasetPayload;
}

interface AnalyticStoryListTableSeries {
  items: AnalyticStoryListTableDataset[];
  pageInfo: PaginationInfo;
}

interface StoryListArgs extends FilterArgs {
  orderBy: AnalyticChartStoryListOrderBy;
  orderDirection: OrderDirection;
  page?: number;
  size?: number;
}

interface AnalyticChartTableTotalDataset {
  label: string;
  value: number;
}

export interface AnalyticChartTableTotalSeries {
  items: AnalyticChartTableTotalDataset[];
}

interface AnalyticChartTableTotalDataset {
  label: string;
  value: number;
}

interface AnalyticChartsData {
  contentActivity: ChartTimeSeries;
  contentInteraction: AnalyticContentInteractionTableSeries;
  contentList: AnalyticContentListTableSeries;
  commentList: AnalyticCommentListTableSeries;
  channelListPosts: AnalyticChannelListTableSeries;
  channelListViews: AnalyticChannelListTableSeries;
  channelListReactions: AnalyticChannelListTableSeries;
  channelListComments: AnalyticChannelListTableSeries;
  channelListShares: AnalyticChannelListTableSeries;
  storyList: AnalyticStoryListTableSeries;
  storyTotal: AnalyticChartTableTotalSeries;
  pushNotificationList: AnalyticPushNotificationListTableSeries;
  pushNotificationTotal: AnalyticChartTableTotalSeries;
  inAppMessageList: AnalyticInAppMessageListTableSeries;
  inAppMessageTotal: AnalyticChartTableTotalSeries;
  userInteraction: AnalyticUserInteractionTableSeries;
  userRoleContentPublished: AnalyticUserRoleContentTableSeries;
  userRoleContentView: AnalyticUserRoleContentTableSeries;
  userRoleContentViewAvg: AnalyticUserRoleContentTableSeries;
  userRoleContentEngagement: AnalyticUserRoleContentTableSeries;
  userRoleContentEngagementAvg: AnalyticUserRoleContentTableSeries;
  userEngagement: ChartTimeSeries;
  userSession: ChartTimeSeries;
  userFunnel: AnalyticUserFunnelTableSeries;
  userFunnelV2: AnalyticUserFunnelTableSeries;
  userFunnelV3: AnalyticUserFunnelTableSeries;
  userDemoFunnel: AnalyticUserFunnelTableSeries;
  userLoginLogout: ChartTimeSeries;
  installUninstallApp: ChartTimeSeries;
  userActive: ChartTimeSeries;
  userActivity: ChartTimeSeries;
  userDemoActivity: ChartTimeSeries;
}

interface AnalyticChartsResponse {
  data: {
    analyticCharts: AnalyticChartsData;
  };
}

interface AnalyticGenerateContentCSVReportResponse {
  data: {
    analyticGenerateContentCSVReport: {
      payload: boolean;
    };
  };
}

interface AnalyticGenerateUserCSVReportArgs extends FilterArgs {
  yearUserSessionPerMonth: number;
}

interface AnalyticGenerateUserCSVReportResponse {
  data: {
    analyticGenerateUserCSVReport: {
      payload: boolean;
    };
  };
}

interface AnalyticGenerateOtherCSVReportResponse {
  data: {
    analyticGenerateOtherCSVReport: {
      payload: boolean;
    };
  };
}

export const analyticsApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    contentActivity: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ channelId, from, to, interval, platform, organisationTags }) => ({
        url: '/graphql/webapp?contentActivity',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticContentFilterArgs!) {
            analyticCharts {
              contentActivity(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              channelId,
              from,
              to,
              platform,
              organisationTags,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.contentActivity,
    }),
    contentInteraction: builder.query<AnalyticContentInteractionTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform, organisationTags }) => ({
        url: '/graphql/webapp?contentInteraction',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticContentFilterArgs!) {
            analyticCharts {
              contentInteraction(filter: $filter) {
                items {
                  label
                  value
                  payload {
                    published
                    interacted
                  }
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
              organisationTags,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.contentInteraction,
    }),
    contentList: builder.query<AnalyticContentListTableSeries, ContentListArgs>({
      query: ({
        channelId,
        from,
        to,
        platform,
        organisationTags,
        storyCardType,
        orderBy,
        orderDirection,
        page = 1,
        size = 15,
      }) => ({
        url: '/graphql/webapp?contentList',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($page: Int, $size: Int, $orderBy: AnalyticContentListOrderByEnum!, $orderDirection: OrderDirectionEnum!, $filter: GetChartContentListFilterArgs!) {
            analyticCharts {
              contentList(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, filter: $filter) {
                items {
                  label
                  value
                  payload {
                    channel {
                      name
                      subdomain
                    }
                    commentReactions {
                      count
                      name
                    }
                    commentReactionsTotal
                    comments
                    notifications
                    reactions {
                      count
                      name
                    }
                    reactionsTotal
                    reactionsPositive
                    reactionsNegative
                    shares
                    storyCard {
                      id
                      type
                      postedAt
                      storyId
                      status
                      externalTags: organisationTags(filters: {types: [EXTERNAL]}) { name }
                    }
                    views
                    plays
                    playDuration
                    playDurationAvg
                  }
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                  hasPreviousPage
                  totalItems
                  totalPages
                }
              }
            }
          }`,
          variables: {
            page,
            size,
            orderBy,
            orderDirection,
            filter: {
              channelId,
              from,
              to,
              platform,
              storyCardType,
              organisationTags,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.contentList,
    }),
    commentList: builder.query<AnalyticCommentListTableSeries, CommentListArgs>({
      query: ({ channelId, from, to, platform, orderDirection, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?commentList',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($page: Int, $size: Int, $orderDirection: OrderDirectionEnum!, $filter: GetChartCommentListFilterArgs!) {
            analyticCharts {
              commentList(page: $page, size: $size, orderDirection: $orderDirection, filter: $filter) {
                items {
                  label
                  value
                  payload {
                    channel {
                      subdomain
                    }
                    comment {
                      id
                      content
                      author {
                        screenName
                        avatar {
                          averageColor
                          externalId
                          externalType
                          id
                          originalFilename
                          rightholder
                          status
                          statusCopyrightId
                          thumb
                          url
                        }
                      }
                      storyCard {
                        id
                        storyId
                        type
                        content {
                          ... on StoryCardThreadContent {
                            title
                          }
                          ... on StoryCardEditorialContent {
                            headline
                          }
                          ... on StoryCardImageContent {
                            headline
                          }
                          ... on StoryCardAudioContent {
                            headline
                          }
                          ... on StoryCardVideoContent {
                            headline
                          }
                          ... on StoryCardPdfContent {
                            headline
                          }
                          ... on StoryCardArticleContent {
                            title
                          }
                          ... on StoryCardPostContent {
                            title
                          }
                          ... on StoryCardQuoteContent {
                            quote
                          }
                        }
                      }
                    }
                    commentReactionsTotal
                    storyCardTitle
                  }
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                  hasPreviousPage
                  totalItems
                  totalPages
                }
              }
            }
          }`,
          variables: {
            page,
            size,
            orderDirection,
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.commentList,
    }),
    channelListPosts: builder.query<AnalyticChannelListTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?channelListPosts',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              channelListPosts(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.channelListPosts,
    }),
    channelListViews: builder.query<AnalyticChannelListTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?channelListViews',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              channelListViews(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.channelListViews,
    }),
    channelListReactions: builder.query<AnalyticChannelListTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?channelListReactions',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              channelListReactions(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
        },
        variables: {
          filter: {
            channelId,
            from,
            to,
            platform,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.channelListReactions,
    }),
    channelListComments: builder.query<AnalyticChannelListTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?channelListComments',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              channelListComments(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.channelListComments,
    }),
    channelListShares: builder.query<AnalyticChannelListTableSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?channelListShares',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              channelListShares(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.channelListShares,
    }),
    storyList: builder.query<AnalyticStoryListTableSeries, StoryListArgs>({
      query: ({ channelId, from, to, platform, orderBy, orderDirection, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?storyList',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($page: Int, $size: Int, $orderBy: AnalyticChartStoryListOrderByEnum!, $orderDirection: OrderDirectionEnum!, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              storyList(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, filter: $filter) {
                items {
                  label
                  payload {
                    channel {
                      subdomain
                    }
                    story {
                      id
                      title
                    }
                    duration
                    durationAvg
                    views
                  }
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                  hasPreviousPage
                  totalItems
                  totalPages
                }
              }
            }
          }`,
          variables: {
            page,
            size,
            orderBy,
            orderDirection,
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.storyList,
    }),
    storyTotal: builder.query<AnalyticChartTableTotalSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?storyTotal',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              storyTotal(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.storyTotal,
    }),
    pushNotificationList: builder.query<
      AnalyticPushNotificationListTableSeries,
      NotificationListArgs
    >({
      query: ({ channelId, from, to, platform, orderBy, orderDirection, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?pushNotificationList',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($page: Int, $size: Int, $orderBy: NotificationListOrderByEnum!, $orderDirection: OrderDirectionEnum!, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              pushNotificationList(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, filter: $filter) {
                items {
                  label
                  value
                  payload {
                    channel {
                      name
                      subdomain
                    }
                    notification {
                      deliveredTime
                      storyCard {
                        id
                        storyId
                      }
                      url
                    }
                    open
                    sent
                  }
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                  hasPreviousPage
                  totalItems
                  totalPages
                }
              }
            }
          }`,
          variables: {
            page,
            size,
            orderBy,
            orderDirection,
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.pushNotificationList,
    }),
    pushNotificationTotal: builder.query<AnalyticChartTableTotalSeries, FilterArgs>({
      query: ({ channelId, from, to, platform }) => ({
        url: '/graphql/webapp?pushNotificationTotal',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              pushNotificationTotal(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.pushNotificationTotal,
    }),
    inAppMessageList: builder.query<AnalyticInAppMessageListTableSeries, NotificationListArgs>({
      query: ({ channelId, from, to, platform, orderBy, orderDirection, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?inAppMessageList',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($page: Int, $size: Int, $orderBy: InAppMessageListOrderByEnum!, $orderDirection: OrderDirectionEnum!, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              inAppMessageList(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, filter: $filter) {
                items {
                  label
                  value
                  payload {
                    channel {
                      name
                    }
                    inAppMessage {
                      title
                      delivery
                      primary {
                        action
                      }
                    }
                    open
                    sent
                  }
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                  hasPreviousPage
                  totalItems
                  totalPages
                }
              }
            }
          }`,
          variables: {
            page,
            size,
            orderBy,
            orderDirection,
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.inAppMessageList,
    }),
    inAppMessageTotal: builder.query<AnalyticChartTableTotalSeries, FilterArgs>({
      query: ({ from, to, channelId, platform }) => ({
        url: '/graphql/webapp?inAppMessageTotal',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              inAppMessageTotal(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              channelId,
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.inAppMessageTotal,
    }),
    userInteraction: builder.query<AnalyticUserInteractionTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userInteraction',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userInteraction(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userInteraction,
    }),
    userRoleContentPublished: builder.query<AnalyticUserRoleContentTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userRoleContentPublished',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userRoleContentPublished(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userRoleContentPublished,
    }),
    userRoleContentView: builder.query<AnalyticUserRoleContentTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userRoleContentView',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userRoleContentView(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userRoleContentView,
    }),
    userRoleContentViewAvg: builder.query<AnalyticUserRoleContentTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userRoleContentViewAvg',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userRoleContentViewAvg(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userRoleContentViewAvg,
    }),
    userRoleContentEngagement: builder.query<AnalyticUserRoleContentTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userRoleContentEngagement',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userRoleContentEngagement(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userRoleContentEngagement,
    }),
    userRoleContentEngagementAvg: builder.query<AnalyticUserRoleContentTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userRoleContentEngagementAvg',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userRoleContentEngagementAvg(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userRoleContentEngagementAvg,
    }),
    userEngagement: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, interval, platform }) => ({
        url: '/graphql/webapp?userEngagement',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userEngagement(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userEngagement,
    }),
    userSession: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, interval, platform }) => ({
        url: '/graphql/webapp?userSession',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userSession(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userSession,
    }),
    userFunnel: builder.query<AnalyticUserFunnelTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userFunnel',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userFunnel(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userFunnel,
    }),
    userFunnelV2: builder.query<AnalyticUserFunnelTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userFunnelV2',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userFunnelV2(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userFunnelV2,
    }),
    userFunnelV3: builder.query<AnalyticUserFunnelTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userFunnelV3',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userFunnelV3(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userFunnelV3,
    }),
    userDemoFunnel: builder.query<AnalyticUserFunnelTableSeries, FilterArgs>({
      query: ({ from, to, platform }) => ({
        url: '/graphql/webapp?userDemoFunnel',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userDemoFunnel(filter: $filter) {
                items {
                  label
                  value
                }
              }
            }
          }`,
          variables: {
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userDemoFunnel,
    }),
    userLoginLogout: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, interval }) => ({
        url: '/graphql/webapp?userLoginLogout',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userLoginLogout(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userLoginLogout,
    }),
    installUninstallApp: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, interval }) => ({
        url: '/graphql/webapp?installUninstallApp',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              installUninstallApp(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.installUninstallApp,
    }),
    userActive: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, interval }) => ({
        url: '/graphql/webapp?userActive',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userActive(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userActive,
    }),
    userActivity: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, platform, interval }) => ({
        url: '/graphql/webapp?userActivity',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userActivity(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userActivity,
    }),
    userDemoActivity: builder.query<ChartTimeSeries, FilterArgsWithInterval>({
      query: ({ from, to, platform, interval }) => ({
        url: '/graphql/webapp?userDemoActivity',
        method: 'POST',
        body: {
          query: `query AnalyticCharts($interval: AnalyticChartPublicIntervalEnum, $filter: GetAnalyticFilterArgs!) {
            analyticCharts {
              userDemoActivity(interval: $interval, filter: $filter) {
                datasets {
                  data
                  label
                }
                interval
                intervals
                labels
              }
            }
          }`,
          variables: {
            interval,
            filter: {
              from,
              to,
              platform,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: AnalyticChartsResponse) =>
        response.data.analyticCharts.userDemoActivity,
    }),
    analyticGenerateContentCSVReport: builder.query<boolean, FilterArgs>({
      query: ({ from, to, channelId, platform, organisationTags }) => ({
        url: '/graphql/webapp?analyticGenerateContentCSVReport',
        method: 'POST',
        body: {
          query: `mutation AnalyticGenerateContentCSVReport($input: AnalyticGenerateCsvReportInput!) {
            analyticGenerateContentCSVReport(input: $input) {
              payload
            }
          }`,
          variables: {
            input: { from, to, channelId, platform, organisationTags },
          },
        },
      }),
      transformResponse: (response: AnalyticGenerateContentCSVReportResponse) =>
        response.data.analyticGenerateContentCSVReport.payload,
    }),
    analyticGenerateUserCSVReport: builder.query<boolean, AnalyticGenerateUserCSVReportArgs>({
      query: ({ from, to, channelId, platform, yearUserSessionPerMonth }) => ({
        url: '/graphql/webapp?analyticGenerateUserCSVReport',
        method: 'POST',
        body: {
          query: `mutation AnalyticGenerateUserCSVReport($input: AnalyticGenerateUserCsvReportInput!) {
            analyticGenerateUserCSVReport(input: $input) {
              payload
            }
          }`,
          variables: {
            input: { from, to, channelId, platform, yearUserSessionPerMonth },
          },
        },
      }),
      transformResponse: (response: AnalyticGenerateUserCSVReportResponse) =>
        response.data.analyticGenerateUserCSVReport.payload,
    }),
    analyticGenerateOtherCSVReport: builder.query<boolean, FilterArgs>({
      query: ({ from, to, channelId, platform }) => ({
        url: '/graphql/webapp?analyticGenerateOtherCSVReport',
        method: 'POST',
        body: {
          query: `mutation AnalyticGenerateOtherCSVReport($input: AnalyticGenerateUserCsvReportInput!){
            analyticGenerateOtherCSVReport(input: $input) {
              payload
            }
          }`,
          variables: {
            input: { from, to, channelId, platform },
          },
        },
      }),
      transformResponse: (response: AnalyticGenerateOtherCSVReportResponse) =>
        response.data.analyticGenerateOtherCSVReport.payload,
    }),
  }),
});

export const {
  useContentActivityQuery,
  useContentInteractionQuery,
  useContentListQuery,
  useCommentListQuery,
  useChannelListPostsQuery,
  useChannelListViewsQuery,
  useChannelListReactionsQuery,
  useChannelListCommentsQuery,
  useChannelListSharesQuery,
  useStoryListQuery,
  useStoryTotalQuery,
  usePushNotificationListQuery,
  usePushNotificationTotalQuery,
  useInAppMessageListQuery,
  useInAppMessageTotalQuery,
  useUserInteractionQuery,
  useUserRoleContentPublishedQuery,
  useUserRoleContentViewQuery,
  useUserRoleContentViewAvgQuery,
  useUserRoleContentEngagementQuery,
  useUserRoleContentEngagementAvgQuery,
  useUserEngagementQuery,
  useUserSessionQuery,
  useUserFunnelQuery,
  useUserFunnelV2Query,
  useUserFunnelV3Query,
  useUserDemoFunnelQuery,
  useUserLoginLogoutQuery,
  useInstallUninstallAppQuery,
  useUserActiveQuery,
  useUserActivityQuery,
  useUserDemoActivityQuery,
} = analyticsApi;
