/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CommentContentUpdateArgs,
  CommentDeleteArgs,
  CommentHideArgs,
  CommentPostInStoryCardArgs,
  CommentReactionArgs,
  NewsFeedArgs,
  StoryCard,
  StoryCardCommentsArgs,
  StoryCardDeleteArgs,
  StoryCardNewsfeedPinnedStatusUpdateArgs,
  StoryCardReactionArgs,
  StoryCardRepostArgs,
  StoryCardUpdateArgs,
  StoryCardsFeedArgs,
  StoryCardsFeedPayload,
  StoryUpdateArgs,
  commentApi,
  newsFeedApi,
  storyApi,
  storyCardApi,
} from '../services';
import { PaginationInfo } from '../shared';

const {
  storyCardReaction,
  storyCardComments,
  storyCardDelete,
  storyCardRepost,
  storyCardUpdate,
  storyCardNewsfeedPinnedStatusUpdate,
} = storyCardApi.endpoints;

const { storyUpdate } = storyApi.endpoints;

const {
  commentContentUpdate,
  commentPostInStoryCard,
  commentDelete,
  commentReaction,
  commentHide,
  commentHighlight,
} = commentApi.endpoints;

const newsFeedHanlder = async (args: NewsFeedArgs, { dispatch }: any) => {
  return dispatch(newsFeedApi.endpoints.newsFeed.initiate({ ...args })).unwrap();
};

export const getNewsFeed = createAsyncThunk('newsFeed', newsFeedHanlder);

export const loadMoreNewsFeed = createAsyncThunk('newsFeed/loadMore', newsFeedHanlder);

export const getStoryFeedHandler = async (args: StoryCardsFeedArgs, { dispatch }: any) => {
  // * special case for news feed filtering when
  // * [StorySection.PINNED_ORG, StorySection.PINNED_CHANNEL].includes(section)
  // * storyId is optional, used 0 as default
  if (!args.storyId) {
    return {
      items: [],
      pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
    } as StoryCardsFeedPayload;
  }

  const storyCardsFeed = await dispatch(
    storyCardApi.endpoints.storyCardsFeed.initiate({ ...args })
  ).unwrap();

  return { ...args, ...storyCardsFeed };
};

export const updateStoryHandler = async (args: StoryUpdateArgs, { dispatch }: any) => {
  return dispatch(storyUpdate.initiate({ ...args })).unwrap();
};

export const updateCardHandler = async (args: StoryCardUpdateArgs, { dispatch }: any) => {
  return dispatch(storyCardUpdate.initiate({ ...args })).unwrap();
};

export const updateStory = createAsyncThunk('updateStory', updateStoryHandler);

export const togglePublishOnStory = createAsyncThunk('togglePublishOnStory', updateStoryHandler);

export const addComment = createAsyncThunk(
  'addComment',
  async (args: CommentPostInStoryCardArgs, { dispatch }) => {
    const comment = await dispatch(commentPostInStoryCard.initiate(args)).unwrap();
    return { ...args, comment };
  }
);

export const editComment = createAsyncThunk(
  'editComment',
  async (args: CommentContentUpdateArgs, { dispatch }) => {
    const comment = await dispatch(commentContentUpdate.initiate(args)).unwrap();
    return { comment };
  }
);

export const deleteComment = createAsyncThunk(
  'deleteComment',
  async (args: CommentDeleteArgs, { dispatch }) => {
    const deleted = await dispatch(commentDelete.initiate(args)).unwrap();
    return { ...args, deleted };
  }
);

export const reactOnComment = createAsyncThunk(
  'reactOnComment',
  async (args: CommentReactionArgs, { dispatch }) => {
    const reactions = await dispatch(commentReaction.initiate(args)).unwrap();
    return { ...args, reactions };
  }
);

export const hideComment = createAsyncThunk(
  'hideComment',
  async (args: CommentHideArgs, { dispatch }) => {
    const comment = await dispatch(commentHide.initiate(args)).unwrap();
    return { comment };
  }
);

export const highlightComment = createAsyncThunk(
  'highlightComment',
  async (args: CommentHideArgs, { dispatch }) => {
    const comment = await dispatch(commentHighlight.initiate(args)).unwrap();
    return { comment };
  }
);

export const reactOnCard = createAsyncThunk(
  'reactOnCard',
  async (args: StoryCardReactionArgs, { dispatch }) => {
    const reactions = await dispatch(storyCardReaction.initiate(args)).unwrap();
    return { ...args, reactions };
  }
);

export const loadMoreComments = createAsyncThunk(
  'loadMoreComments',
  async (args: StoryCardCommentsArgs, { dispatch }) => {
    const { items } = await dispatch(storyCardComments.initiate(args)).unwrap();
    return { ...args, items };
  }
);

export const addCard = createAction<StoryCard>('addCard');

export const editCard = createAction<StoryCard>('editCard');

export const deleteCard = createAsyncThunk(
  'deleteCard',
  async (args: StoryCardDeleteArgs, { dispatch }) => {
    const deleted = await dispatch(storyCardDelete.initiate(args)).unwrap();
    return { ...args, deleted };
  }
);

export const repostCard = createAsyncThunk(
  'repostCard',
  async (args: StoryCardRepostArgs, { dispatch }) => {
    return await dispatch(storyCardRepost.initiate(args)).unwrap();
  }
);

export const pinCard = createAsyncThunk(
  'pinCard',
  async (args: StoryCardNewsfeedPinnedStatusUpdateArgs, { dispatch }) => {
    const payload = await dispatch(storyCardNewsfeedPinnedStatusUpdate.initiate(args)).unwrap();
    return { ...args, payload };
  }
);

export const togglePublishOnCard = createAsyncThunk('togglePublishOnCard', updateCardHandler);

export const toggleCardSection = createAsyncThunk('toggleCardSection', updateCardHandler);
