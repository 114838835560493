import PubNub from 'pubnub';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Channels,
  ChannelsUnreadCount,
  ChannelTypingUsers,
  ChatContext,
  UserContext,
} from '../../context';
import { Chat, ChatTypes, useGetChannelChatsQuery, User } from '../../services';
import { DropdownMenu, IconLabel, InputField, Popup, useDebounce } from '../../shared';
import { ChatHistory } from './ChatHistory';
import { ChatsList } from './ChatsList';
import { CreateChatModal } from './CreateChatModal';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './Chats.module.scss';

export enum ChatTabsTypes {
  all,
  direct,
  public,
}

export const Chats: FunctionComponent = () => {
  const { t } = useTranslation();

  const { data } = useGetChannelChatsQuery();

  const userId = useContext(UserContext).userProfile.id;

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [searchActive, setSearchActive] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  const debouncedSearch = useDebounce<string>(search);

  const [activeChat, setActiveChat] = useState<Chat | null>(null);

  const [chats, setChats] = useState<Chat[]>([]);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(ChatTabsTypes.all);

  const [pubnub, setPubnub] = useState<PubNub | null>(null);

  const [channels, setChannels] = useState<Channels | null>(null);

  const [channelsChanges, setChannelsChanges] = useState<Channels | null>(null);

  const [channelsUnreadCount, setChannelsUnreadCount] = useState<ChannelsUnreadCount | null>(null);

  const [chatUsers, setChatUsers] = useState<User[]>([]);

  const [channelTypingUsers, setChannelTypingUsers] = useState<ChannelTypingUsers | null>(null);

  const [newChatType, setNewChatType] = useState<ChatTypes>(ChatTypes.p2p);

  const [isCreateMenuOpen, setIsCreateMenuOpen] = useState<boolean>(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (pubnub || !data) {
      return;
    }

    const { chats, publishKey, subscribeKey, tokenV3 } = data;

    setChats(chats);

    setPubnub(
      new PubNub({ publishKey, subscribeKey, userId: userId.toString(), authKey: tokenV3 })
    );

    setInitialLoad(false);
  }, [data, pubnub, userId]);

  const createHandler = useCallback((type: ChatTypes) => {
    setIsCreateMenuOpen(false);
    setNewChatType(type);
    setIsCreateModalOpen(true);
  }, []);

  const dropdownMenuContent = useMemo(() => {
    return (
      <>
        <IconLabel
          iconId={'user'}
          iconSize={18}
          label={t('chatActions.create-direct-chat')}
          className={dropdownMenuClasses['dropdown-menu__item--small']}
          onClick={() => createHandler(ChatTypes.p2p)}
          singleColor
        />

        <IconLabel
          iconId={'users'}
          iconSize={18}
          label={t('chatActions.create-group-chat')}
          className={dropdownMenuClasses['dropdown-menu__item--small']}
          onClick={() => createHandler(ChatTypes.group)}
          singleColor
        />
      </>
    );
  }, [createHandler, t]);

  const onSearchClose = useCallback(() => {
    setSearchActive(false);
    setSearch('');
  }, []);

  const searchContent = useMemo(() => {
    if (searchActive) {
      return (
        <InputField
          autoFocus
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          placeholder={t('common.search')}
          icon={
            <IconLabel
              iconId={'close'}
              iconSize={18}
              color={'#B3B3B3'}
              onClick={onSearchClose}
              singleColor
            />
          }
        />
      );
    }

    return (
      <>
        <div className={classes['chats__header-search-title']}>{t('adminLayoutMenu.chats')}</div>
        <div className={classes['chats__header-search-icons']}>
          <IconLabel iconId={'search'} iconSize={18} onClick={() => setSearchActive(true)} />
          <Popup
            isOpen={isCreateMenuOpen}
            setIsOpen={setIsCreateMenuOpen}
            iconId={'add-plus'}
            iconSize={18}
            bodyTop={'1.5rem'}
            bodyRight={'0'}
            body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
          />
        </div>
      </>
    );
  }, [dropdownMenuContent, isCreateMenuOpen, onSearchClose, search, searchActive, t]);

  if (initialLoad) {
    return <Skeleton height={'10rem'} />;
  }

  if (!pubnub) {
    return null;
  }

  return (
    <ChatContext.Provider
      value={{
        pubnub,
        activeChat,
        setActiveChat,
        chats,
        setChats,
        selectedTabIndex,
        setSelectedTabIndex,
        channels,
        setChannels,
        channelsChanges,
        setChannelsChanges,
        channelsUnreadCount,
        setChannelsUnreadCount,
        chatUsers,
        setChatUsers,
        channelTypingUsers,
        setChannelTypingUsers,
      }}
    >
      <div className={classes['chats']}>
        <div className={classes['chats__header']}>
          <div className={classes['chats__header-search']}>{searchContent}</div>
          <ChatsList search={debouncedSearch} />
        </div>
        <ChatHistory />
      </div>
      {isCreateModalOpen && (
        <CreateChatModal
          chat={{ type: newChatType } as Chat}
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
    </ChatContext.Provider>
  );
};
