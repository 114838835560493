import { FunctionComponent, memo } from 'react';
import sprite from '../../../assets/icons/sprite.svg';

import classNames from 'classnames';
import classes from './Check.module.scss';

export enum CheckLabelSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface CheckProps {
  checked: boolean;
  label?: string;
  labelSize?: CheckLabelSizes;
  onChange?: (checked: boolean) => void;
  className?: string;
}

export const Check: FunctionComponent<CheckProps> = memo(
  ({ checked, label, labelSize = CheckLabelSizes.medium, onChange, className }) => {
    return (
      <div
        className={classNames(
          classes['check'],
          classes[`check--${labelSize}`],
          { [classes['check--checked']]: checked },
          { [classes['check--has-label']]: label },
          className
        )}
        onClick={() => onChange?.(!checked)}
      >
        {checked && (
          <svg className={classes['check__icon']}>
            <use href={`${sprite}#checkmark`} />
          </svg>
        )}

        {label && <span className={classes['check__label']}>{label}</span>}
      </div>
    );
  }
);
