import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import {
  storyCardApi,
  StoryCardsChannelSearchFilters,
  useGetStoriesByChannelIdQuery,
} from '../../../services';
import {
  CookieActionType,
  cookieOptions,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  optionalArrayItem,
  SEARCH_ADMIN_COOKIE_KEY,
  SelectItem,
  useAppDispatch,
  useAuthorsFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
} from '../../../shared';
import { getSearchFeed } from '../../../slices';

import classes from './SearchFeedAdminRightSide.module.scss';

export const SearchFeedAdminRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [fetchChannelAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByChannelId.useLazyQuery();

  const { data: stories, isFetching: isFetchingStories } = useGetStoriesByChannelIdQuery({
    channelId,
  });

  const storyOptions = useMemo(() => {
    return (
      stories?.map(({ id, title }) => {
        return { id, title } as SelectItem;
      }) ?? []
    );
  }, [stories]);

  const query = (searchParams.get('qs') ?? '').replace(/\\|"|'/g, '');

  const [cookies, setCookie] = useCookies([SEARCH_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[SEARCH_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue, getDateRangeValue } = useFilter();

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter(filter, fetchChannelAuthors, { channelId });

  const { group: cardTypeGroup } = useCardTypeFilter();

  const { group: cardStatusGroup } = useCardStatusFilter();

  const storiesGroupId = 'stories';

  const groups: FilterGroup[] = [
    authorsGroup,
    ...optionalArrayItem(!isFetchingStories, {
      id: storiesGroupId,
      title: t('storiesFilter.title'),
      value:
        getGroupValue({
          filter,
          groupId: storiesGroupId,
          multiCheck: true,
          propToMap: 'id',
        }) ?? [],
      dropdown: {
        disabled: isFetchingStories,
        options: storyOptions,
        staticListPosition: false,
        search: true,
        multiCheck: true,
      },
      allowReset: true,
    }),
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const authorId = getGroupValue({
        filter,
        groupId: authorsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const storyId = getGroupValue({
        filter,
        groupId: storiesGroupId,
        multiCheck: true,
        propToMap: 'id',
      });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: cardTypeGroup.id, multiCheck: true });

      const status = getGroupValue({ filter, groupId: cardStatusGroup.id, multiCheck: true });

      const requestFilterToSave: StoryCardsChannelSearchFilters = {
        ...(authorId?.length && { authorId }),
        ...(storyId?.length && { storyId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const requestFilter: StoryCardsChannelSearchFilters = {
        ...requestFilterToSave,
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        SEARCH_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions({ action: CookieActionType.SET })
      );

      dispatch(getSearchFeed({ channelId, filters: { ...requestFilter, query } }));

      setFilter(filter);
    },
    [
      authorsGroup.id,
      cardStatusGroup.id,
      cardTypeGroup.id,
      channelId,
      dispatch,
      getDateRangeValue,
      getGroupValue,
      query,
      setCookie,
    ]
  );

  return (
    <div className={classes['search-feed-admin-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};
